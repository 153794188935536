import { Account } from '@arrived/models';

import { VERIFICATION_STATE, VERIFICATION_STEP } from '../models';
import { VerificationStepState } from '../VerificationSteps';

export const handleBrokerageAccountVerification = (
  steps: Record<VERIFICATION_STEP, VerificationStepState>,
  account?: Account,
) => {
  const verificationState = account != null ? VERIFICATION_STATE.COMPLETE : VERIFICATION_STATE.INCOMPLETE;

  steps[VERIFICATION_STEP.ACCOUNT].state = verificationState;
  steps[VERIFICATION_STEP.ACCOUNT].disableLink = verificationState === VERIFICATION_STATE.COMPLETE;
};
