import { useMemo } from 'react';

import { VERIFICATION_STATE } from '../models';
import {
  ProgressStep,
  VerificationStateEmojiMap,
  VerificationStepperHeaderStateMap,
} from '../VerificationStepperHeaderState';
import { VerificationStepState } from '../VerificationSteps';

/**
 * Hook is used to determine what state to render the VerificationStepperHeader component in.
 * It will use the state in order of the following precedence: error -> pending -> incomplete
 * @param steps Steps state to condition off of.
 * @returns [progress towards the next step (out of 100), metadata for the VerificationStepperHeader component]
 */
export const useVerificationStepperHeaderState = (steps: VerificationStepState[]) => {
  return useMemo<[number, ProgressStep]>(() => {
    let errorStep: VerificationStepState | undefined;
    let pendingStep: VerificationStepState | undefined;
    let incompleteStep: VerificationStepState | undefined;
    let completedSteps = 0;
    steps.forEach((step) => {
      if (step.state === VERIFICATION_STATE.INCOMPLETE && !incompleteStep) incompleteStep = step;
      if (step.state === VERIFICATION_STATE.PENDING && !pendingStep) pendingStep = step;
      if (step.state === VERIFICATION_STATE.ERROR) errorStep = step;
      if (step.state === VERIFICATION_STATE.COMPLETE) completedSteps++;
    });

    const progress = (completedSteps / steps.length) * 100;
    const progressState = VerificationStepperHeaderStateMap[progress];

    if (errorStep) {
      progressState.description = errorStep.messaging[VERIFICATION_STATE.ERROR];
      progressState.emoji = VerificationStateEmojiMap.BLOCKED;
      if (errorStep.headerMessaging?.[VERIFICATION_STATE.ERROR]) {
        progressState.title = errorStep.headerMessaging[VERIFICATION_STATE.ERROR];
      }
      return [progress, progressState];
    }

    if (pendingStep) {
      progressState.description = pendingStep.messaging[VERIFICATION_STATE.PENDING];
      if (pendingStep.headerMessaging?.[VERIFICATION_STATE.PENDING]) {
        progressState.title = pendingStep.headerMessaging[VERIFICATION_STATE.PENDING];
      }
      return [progress, progressState];
    }

    if (incompleteStep) {
      progressState.description = incompleteStep.messaging[VERIFICATION_STATE.INCOMPLETE];
      if (incompleteStep.headerMessaging?.[VERIFICATION_STATE.INCOMPLETE]) {
        progressState.title = incompleteStep.headerMessaging[VERIFICATION_STATE.INCOMPLETE];
      }
      return [progress, progressState];
    }

    return [progress, progressState];
  }, [steps]);
};
