import { useEffect, useState } from 'react';

import { useVerificationStepperSteps } from './useVerificationStepperSteps';

export const useIsShowingStepper = () => {
  const [isShowing, setIsShowing] = useState(false);
  const { isVerified, isLoading, shouldHideStepperDueToAccountStateError } = useVerificationStepperSteps();

  // Handles conditionally showing the stepper if the user is not verified.
  // Also want to prevent showing the stepper if abacus is not responding as usual during an incident.
  useEffect(() => {
    if (!isVerified && !isLoading && !shouldHideStepperDueToAccountStateError) {
      setIsShowing(true);
    } else if (isVerified) {
      const timeout = setTimeout(() => {
        setIsShowing(false);
      }, 5000);

      return () => {
        clearTimeout(timeout);
      };
    }
  }, [isVerified, isLoading, shouldHideStepperDueToAccountStateError]);

  return [isShowing, isShowing, isShowing];
};
