import { ReactNode } from 'react';

import { FormattedMessage } from 'react-intl';
import { LinkProps } from 'react-router-dom';

import { ROUTES } from '@arrived/common';

import { VERIFICATION_STATE, VERIFICATION_STEP } from './models';

export interface VerificationStepItemProps {
  /**
   * Controls the card render of the step
   */
  state: VERIFICATION_STATE;
  /**
   * Controls the small text in the card
   */
  text: ReactNode;
  /**
   * Override and disable the card's link functionality.
   */
  disableLink?: boolean;
  /**
   * By default, cards link to a route.
   * Pass a given route via this prop.
   */
  to?: LinkProps['to'];
  /**
   * Whether or not to render the minimized version of the card.
   */
  minimized?: boolean;
}

export interface VerificationStepState extends VerificationStepItemProps {
  /**
   * Provides detailed messaging for each step and status.
   */
  messaging: {
    [VERIFICATION_STATE.ERROR]?: ReactNode;
    [VERIFICATION_STATE.INCOMPLETE]?: ReactNode;
    [VERIFICATION_STATE.PENDING]?: ReactNode;
  };
  /**
   * Optional messaging that can be used to modify the header for the stepper.
   */
  headerMessaging?: {
    [VERIFICATION_STATE.ERROR]?: ReactNode;
    [VERIFICATION_STATE.INCOMPLETE]?: ReactNode;
    [VERIFICATION_STATE.PENDING]?: ReactNode;
  };
}

/**
 * Provide default state of each step here.
 * useVerificationStepperSteps will provide overrides as needed for various permutations
 * of messaging and state status. If there are no overrides,
 * the messaging and states defined here will be used.
 */

const EmailStep = (): VerificationStepState => ({
  state: VERIFICATION_STATE.INCOMPLETE,
  text: <FormattedMessage id="verificationBanner.email" />,
  to: ROUTES.profile.profileAccount,
  messaging: {
    [VERIFICATION_STATE.INCOMPLETE]: <FormattedMessage id="verificationBanner.email.banner.incomplete" />,
  },
});

const AccountStep = (): VerificationStepState => ({
  state: VERIFICATION_STATE.INCOMPLETE,
  text: <FormattedMessage id="verificationBanner.investmentAccount" />,
  to: ROUTES.accounts.createAccount,
  messaging: {
    [VERIFICATION_STATE.INCOMPLETE]: <FormattedMessage id="verificationBanner.investmentAccount.banner.incomplete" />,
    [VERIFICATION_STATE.PENDING]: <FormattedMessage id="brokerage.account.kyc.pending" />,
    [VERIFICATION_STATE.ERROR]: <FormattedMessage id="brokerage.account.kyc.not.run" />,
  },
});

const BankStep = (): VerificationStepState => ({
  state: VERIFICATION_STATE.INCOMPLETE,
  text: <FormattedMessage id="verificationBanner.bank" />,
  to: ROUTES.accounts.addBank,
  // Disabled until the user has created an account
  disableLink: true,
  messaging: {
    [VERIFICATION_STATE.ERROR]: <FormattedMessage id="verificationBanner.bank.banner.error" />,
    [VERIFICATION_STATE.INCOMPLETE]: <FormattedMessage id="verificationBanner.bank.banner.incomplete" />,
    [VERIFICATION_STATE.PENDING]: <FormattedMessage id="verificationBanner.bank.banner.pending" />,
  },
});

const IdentityStep = (): VerificationStepState => ({
  state: VERIFICATION_STATE.INCOMPLETE,
  text: <FormattedMessage id="verificationBanner.identity" />,
  to: ROUTES.profile.base,
  // Disabled until the user has created an account
  disableLink: true,
  messaging: {
    [VERIFICATION_STATE.ERROR]: <FormattedMessage id="verificationBanner.identity.banner.error" />,
    [VERIFICATION_STATE.INCOMPLETE]: <FormattedMessage id="verificationBanner.identity.banner.incomplete" />,
    [VERIFICATION_STATE.PENDING]: <FormattedMessage id="verificationBanner.identity.banner.pending" />,
  },
});

/**
 * Creates an initial state for the steps to later be overwritten as necessary.
 * @returns ordered steps and mapped steps
 */
export const createVerificationSteps = () => {
  const ordered = [EmailStep(), AccountStep(), BankStep(), IdentityStep()];

  return {
    ordered,
    map: {
      [VERIFICATION_STEP.EMAIL]: ordered[0],
      [VERIFICATION_STEP.ACCOUNT]: ordered[1],
      [VERIFICATION_STEP.BANK]: ordered[2],
      [VERIFICATION_STEP.IDENTITY]: ordered[3],
    },
  };
};

export type VerificationStepsReturn = ReturnType<typeof createVerificationSteps>;
