import { FormattedMessage } from 'react-intl';

import { VERIFICATION_STATE, VERIFICATION_STEP } from '../models';
import { VerificationStepState } from '../VerificationSteps';

export const handleEmailVerification = (
  steps: Record<VERIFICATION_STEP, VerificationStepState>,
  email?: string,
  emailVerified?: boolean,
) => {
  if (email) {
    if (!emailVerified) {
      steps[VERIFICATION_STEP.EMAIL].state = VERIFICATION_STATE.INCOMPLETE;
      steps[VERIFICATION_STEP.EMAIL].messaging[VERIFICATION_STATE.INCOMPLETE] = (
        <FormattedMessage id="verificationBanner.email.banner.incomplete" values={{ email }} />
      );
    } else {
      steps[VERIFICATION_STEP.EMAIL].state = VERIFICATION_STATE.COMPLETE;
    }
  }
};
