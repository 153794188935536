import { forwardRef, useState } from 'react';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Box, BoxProps } from '@mui/material';

import { useTheme } from '@arrived/bricks';

import { VerificationStepItem } from './VerificationStepItem';
import { VerificationStepperHeader, VerificationStepperHeaderProps } from './VerificationStepperHeader';
import { VerificationStepState } from './VerificationSteps';

interface VerificationStepperProps {
  steps: VerificationStepState[];
  minimized?: boolean;
  boxProps?: BoxProps;
  context?: VerificationStepperHeaderProps['context'];
}

export const VerificationStepper = forwardRef<HTMLDivElement, VerificationStepperProps>(
  ({ steps, minimized, boxProps, context }, ref) => {
    const [open, setOpen] = useState(false);
    const bricksTheme = useTheme();

    return (
      <Box
        ref={ref}
        p={{ xs: 2, lg: minimized ? 1 : 2 }}
        gap={{ xs: 'unset', lg: 2 }}
        sx={{
          ...boxProps?.sx,
          backgroundColor: bricksTheme['surface.neutral.default'].variable,
          transition: `all 0.5s ease-in-out`,
        }}
        display="flex"
        position={minimized ? 'sticky' : undefined}
        top={minimized ? '0' : undefined}
        flexDirection={{ xs: 'column', lg: 'row' }}
        alignItems="center"
        width="100%"
        maxWidth="xl"
        overflow="hidden"
        mx="auto"
        role="banner"
        {...boxProps}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          width={{ xs: '100%', lg: '40%' }}
          maxWidth={{ xs: 'unset', lg: '500px' }}
        >
          <VerificationStepperHeader steps={steps} open={open} minimized={minimized} context={context} />
          <Box
            display={{ xs: 'unset', lg: 'none' }}
            fontSize="2rem"
            color={(theme) => theme.palette.darkened.seal}
            sx={{ cursor: 'pointer' }}
            onClick={() => setOpen(!open)}
          >
            <ExpandMoreIcon
              fontSize="inherit"
              color="inherit"
              sx={{
                transition: 'all ease-in-out 0.5s',
                transform: `rotate(${open ? '180deg' : '0deg'})`,
              }}
            />
          </Box>
        </Box>
        <Box
          width="100%"
          height="100%"
          overflow="hidden"
          sx={{
            '.verification-steps': {
              maxHeight: open ? { xs: '240px', lg: 'unset' } : undefined,
            },
          }}
        >
          <Box
            className="verification-steps"
            height={{ xs: 'unset', lg: '100%' }}
            maxHeight={{ xs: 0, lg: 'unset' }}
            sx={{ transition: 'max-height 1s ease-in-out' }}
          >
            <Box
              pt={{ xs: 2, lg: 'unset' }}
              height={{ xs: 'unset', lg: '100%' }}
              width={{ xs: '100%', lg: 'unset' }}
              display="flex"
              flexDirection={{ xs: 'column', md: 'row' }}
              flex="1"
              justifyContent="space-between"
              gap={{ xs: 1, lg: 2 }}
            >
              {steps.map((step, idx) => (
                <VerificationStepItem key={idx} {...step} minimized={minimized} />
              ))}
            </Box>
          </Box>
        </Box>
      </Box>
    );
  },
);
