import { FormattedMessage } from 'react-intl';

import { BrokerageAccountSetupState, ExternalAccountSetupState } from '@arrived/common';

import { VERIFICATION_STATE, VERIFICATION_STEP } from '../models';
import { VerificationStepState } from '../VerificationSteps';

/**
 * Handle overrides at the end once the steps are computed.
 */
export const handleEndOverrides = (
  steps: Record<VERIFICATION_STEP, VerificationStepState>,
  externalAccountSetupState: ExternalAccountSetupState | null,
  brokerageAccountSetupState: BrokerageAccountSetupState,
  hasIdDocument: boolean,
) => {
  if (brokerageAccountSetupState === BrokerageAccountSetupState.PENDING_VERIFICATION) {
    if (hasIdDocument && externalAccountSetupState === ExternalAccountSetupState.VERIFY_BANK) {
      steps[VERIFICATION_STEP.IDENTITY].messaging[VERIFICATION_STATE.PENDING] = (
        <FormattedMessage id="verificationBanner.identity.banner.bankPending" />
      );
    }
  }

  // We have to handle a special case for when the user has not yet linked a bank and has their
  // identity step (KYC/AML) pending, we modify the message to indicate that we're waiting for the
  // user to add a bank account to verify their identity.
  if (
    externalAccountSetupState === ExternalAccountSetupState.LINK_BANK &&
    steps[VERIFICATION_STEP.IDENTITY].state === VERIFICATION_STATE.PENDING
  ) {
    steps[VERIFICATION_STEP.IDENTITY].messaging[VERIFICATION_STATE.PENDING] = (
      <FormattedMessage id="verificationBanner.identify.banner.pending-bank" />
    );
  }
};
