import React, { ReactNode, useMemo } from 'react';

import { Link } from 'react-router-dom';

import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CancelRoundedIcon from '@mui/icons-material/CancelRounded';
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import { Box, BoxProps, SxProps, Theme, Typography } from '@mui/material';

import { setColorAlpha, tokens } from '@arrived/bricks';

import { VERIFICATION_STATE } from './models';
import { VerificationStepItemProps } from './VerificationSteps';

interface VerificationStepItem {
  icon: ReactNode;
  styles?: SxProps<Theme>;
}

const IncompleteIcon = (
  <Box
    sx={{
      width: '1.4rem',
      height: '1.4rem',
      boxShadow: ({ palette }) => `inset 0 0 0 2px ${palette.background.iceBlue}`,
      backgroundColor: ({ palette }) => palette.lightened.bare,
      borderRadius: '50%',
    }}
  />
);

const VerificationStepStateMap: Record<VERIFICATION_STATE, VerificationStepItem> = {
  [VERIFICATION_STATE.COMPLETE]: {
    icon: <CheckCircleRoundedIcon fontSize="inherit" color="success" />,
    styles: { backgroundColor: setColorAlpha(tokens.color['positive.light.400'].val, 0.2) },
  },
  [VERIFICATION_STATE.INCOMPLETE]: {
    icon: IncompleteIcon,
    styles: { backgroundColor: ({ palette }) => palette.neutrals.platinum },
  },
  [VERIFICATION_STATE.PENDING]: {
    icon: (
      <Box component="span" className="emoji" role="img" aria-label="hourglass" aria-hidden="false">
        {'⌛'}
      </Box>
    ),
    styles: { backgroundColor: ({ palette }) => palette.quaternary[100] },
  },
  [VERIFICATION_STATE.ERROR]: {
    icon: <CancelRoundedIcon fontSize="inherit" color="error" />,
    styles: { backgroundColor: ({ palette }) => palette.coral[50] },
  },
};

export const VerificationStepItem = ({ state, text, disableLink, to, minimized }: VerificationStepItemProps) => {
  const stateValues = useMemo(() => VerificationStepStateMap[state], [state]);
  const isNavigateUsed = state !== VERIFICATION_STATE.COMPLETE && !disableLink && to;

  const linkProps = isNavigateUsed ? { to } : undefined;
  const hoverStyling: BoxProps['sx'] = isNavigateUsed
    ? {
        '&:hover': {
          border: ({ palette }) => `1px solid ${palette.secondary.main}`,
          '.bottom-icon': {
            color: ({ palette }) => palette.secondary.main,
          },
        },
      }
    : undefined;

  return (
    <Box
      component={isNavigateUsed ? Link : undefined}
      {...linkProps}
      display="flex"
      flex="1"
      flexDirection={{ xs: 'row', md: minimized ? 'row' : 'column' }}
      px={1}
      py={{ xs: 1, md: minimized ? 1 : 2 }}
      gap={1}
      border="1px solid transparent"
      alignItems={minimized ? 'center' : 'unset'}
      sx={{
        ...stateValues.styles,
        ...hoverStyling,
        transition: 'all 0.5s ease-in-out',
      }}
    >
      <Box display="flex" alignItems="center" height="1.5rem" fontSize="1.5rem">
        {stateValues.icon}
      </Box>
      <Box display="flex" flex="1" fontSize="1rem" alignItems={minimized ? 'center' : 'end'}>
        <Typography variant="body2" flex="1" height={{ xs: '100%', md: 'unset' }}>
          {text}
        </Typography>
        {isNavigateUsed && (
          <Typography className="bottom-icon" display="flex" color="gray.500">
            <ArrowForwardIcon color="inherit" fontSize="inherit" />
          </Typography>
        )}
      </Box>
    </Box>
  );
};
