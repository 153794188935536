import { FormattedMessage } from 'react-intl';

import { BrokerageAccountSetupState } from '@arrived/common';

import { VERIFICATION_STATE, VERIFICATION_STEP } from '../models';
import { VerificationStepState } from '../VerificationSteps';

export const handleIdentityVerification = (
  steps: Record<VERIFICATION_STEP, VerificationStepState>,
  brokerageAccountSetupState: BrokerageAccountSetupState,
  hasIdDocument: boolean,
) => {
  if (brokerageAccountSetupState === BrokerageAccountSetupState.PENDING_VERIFICATION) {
    steps[VERIFICATION_STEP.IDENTITY].state = VERIFICATION_STATE.PENDING;
    if (!hasIdDocument) {
      steps[VERIFICATION_STEP.IDENTITY].messaging[VERIFICATION_STATE.PENDING] = (
        <FormattedMessage id="verificationBanner.identity.banner.pending" />
      );
      steps[VERIFICATION_STEP.IDENTITY].text = <FormattedMessage id="verificationBanner.identity.documents" />;
    }
  }

  if (brokerageAccountSetupState === BrokerageAccountSetupState.PENDING_COMPLIANCE_VERIFICATION) {
    steps[VERIFICATION_STEP.IDENTITY].state = VERIFICATION_STATE.PENDING;
    steps[VERIFICATION_STEP.IDENTITY].messaging[VERIFICATION_STATE.PENDING] = (
      <FormattedMessage id="verificationBanner.identity.banner.pending-compliance-verification" />
    );
  }

  if (brokerageAccountSetupState === BrokerageAccountSetupState.UPLOAD_DOCS) {
    steps[VERIFICATION_STEP.IDENTITY].state = VERIFICATION_STATE.ERROR;
    steps[VERIFICATION_STEP.IDENTITY].disableLink = false;
    steps[VERIFICATION_STEP.IDENTITY].messaging[VERIFICATION_STATE.ERROR] = (
      <FormattedMessage id="verificationBanner.identity.banner.error.upload" />
    );
    steps[VERIFICATION_STEP.IDENTITY].text = <FormattedMessage id="verificationBanner.identity.documents" />;
  }

  if (brokerageAccountSetupState === BrokerageAccountSetupState.UPDATE_SSN) {
    steps[VERIFICATION_STEP.IDENTITY].state = VERIFICATION_STATE.ERROR;
    steps[VERIFICATION_STEP.IDENTITY].disableLink = false;
    steps[VERIFICATION_STEP.IDENTITY].messaging[VERIFICATION_STATE.ERROR] = (
      <FormattedMessage id="verificationBanner.identity.banner.error.ssn" />
    );
    steps[VERIFICATION_STEP.IDENTITY].text = <FormattedMessage id="verificationBanner.identity.ssn" />;
  }

  if (brokerageAccountSetupState === BrokerageAccountSetupState.REJECTED) {
    steps[VERIFICATION_STEP.IDENTITY].state = VERIFICATION_STATE.ERROR;
    steps[VERIFICATION_STEP.IDENTITY].text = <FormattedMessage id="verificationBanner.identity.error" />;
    steps[VERIFICATION_STEP.IDENTITY].headerMessaging = {
      [VERIFICATION_STATE.ERROR]: <FormattedMessage id="verificationBanner.identify.error-header" />,
    };
  }

  if (brokerageAccountSetupState === BrokerageAccountSetupState.KYC_PENDING_SYNC) {
    steps[VERIFICATION_STEP.IDENTITY].state = VERIFICATION_STATE.PENDING;
    steps[VERIFICATION_STEP.IDENTITY].messaging[VERIFICATION_STATE.PENDING] = (
      <FormattedMessage id="brokerage.account.kyc.pending" />
    );
  }

  if (brokerageAccountSetupState === BrokerageAccountSetupState.COMPLETED) {
    steps[VERIFICATION_STEP.IDENTITY].state = VERIFICATION_STATE.COMPLETE;
  }
};
