import { useEffect } from 'react';
import { AppState } from 'react-native';

import { useArrivedAuth0 } from '@arrived/arrived-auth0';

/**
 * Ensures email is verified when switching between tabs
 */
export const useEmailVerificationChecker = () => {
  const { user, refreshUser } = useArrivedAuth0();

  useEffect(() => {
    const listener = AppState.addEventListener('change', (state) => {
      if (state === 'active' && user?.emailVerified === false) {
        refreshUser();
      }
    });

    return () => {
      listener.remove();
    };
  }, [user?.emailVerified]);
};
