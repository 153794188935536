import { FormattedMessage } from 'react-intl';

import { ExternalAccountSetupState } from '@arrived/common';

import { VERIFICATION_STATE, VERIFICATION_STEP } from '../models';
import { VerificationStepState } from '../VerificationSteps';

export const handleBankLinkVerification = (
  steps: Record<VERIFICATION_STEP, VerificationStepState>,
  externalAccountSetupState: ExternalAccountSetupState | null,
) => {
  if (externalAccountSetupState !== ExternalAccountSetupState.VERIFIED) {
    steps[VERIFICATION_STEP.BANK].disableLink = false;
  }

  if (externalAccountSetupState === ExternalAccountSetupState.LINK_BANK) {
    steps[VERIFICATION_STEP.BANK].state = VERIFICATION_STATE.INCOMPLETE;
  }

  if (externalAccountSetupState === ExternalAccountSetupState.VERIFY_BANK) {
    steps[VERIFICATION_STEP.BANK].state = VERIFICATION_STATE.PENDING;
    steps[VERIFICATION_STEP.BANK].messaging[VERIFICATION_STATE.PENDING] = (
      <FormattedMessage id="verificationBanner.bank.banner.pending.deposits" />
    );
    steps[VERIFICATION_STEP.BANK].text = <FormattedMessage id="verificationBanner.verify-bank" />;
  }

  if (externalAccountSetupState === ExternalAccountSetupState.VERIFIED) {
    steps[VERIFICATION_STEP.BANK].state = VERIFICATION_STATE.COMPLETE;
  }
};
