import { ReactNode } from 'react';

import { FormattedMessage } from 'react-intl';

export const VerificationStateEmojiMap = {
  GET_READY: {
    symbol: '👋',
    label: 'get ready',
  },
  HALF_WAY: {
    symbol: '👏',
    label: 'half way',
  },
  ALMOST: {
    symbol: '🙌',
    label: 'almost',
  },
  VERIFIED: {
    symbol: '🥳',
    label: 'verified',
  },
  BLOCKED: {
    symbol: '✋',
    label: 'blocked',
  },
} as const;

export type VerificationStateEmojiMap = typeof VerificationStateEmojiMap;
export type VerificationStateEmoji = VerificationStateEmojiMap[keyof VerificationStateEmojiMap];

export interface ProgressStep {
  title: ReactNode;
  description: ReactNode;
  emoji: VerificationStateEmoji;
}

export const VerificationStepperHeaderStateMap: Record<number, ProgressStep> = {
  0: {
    title: <FormattedMessage id="verificationBanner.progress.ready.title" />,
    description: <FormattedMessage id="verificationBanner.progress.ready.description" />,
    emoji: VerificationStateEmojiMap.GET_READY,
  },
  25: {
    title: <FormattedMessage id="verificationBanner.progress.ready.title" />,
    description: <FormattedMessage id="verificationBanner.progress.ready.description" />,
    emoji: VerificationStateEmojiMap.GET_READY,
  },
  50: {
    title: <FormattedMessage id="verificationBanner.progress.halfway.title" />,
    description: <FormattedMessage id="verificationBanner.progress.halfway.description" />,
    emoji: VerificationStateEmojiMap.HALF_WAY,
  },
  75: {
    title: <FormattedMessage id="verificationBanner.progress.almost.title" />,
    description: <FormattedMessage id="verificationBanner.progress.almost.description" />,
    emoji: VerificationStateEmojiMap.ALMOST,
  },
  100: {
    title: <FormattedMessage id="verificationBanner.progress.done.title" />,
    description: <FormattedMessage id="verificationBanner.progress.done.description" />,
    emoji: VerificationStateEmojiMap.VERIFIED,
  },
};
