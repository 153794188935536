import { ReactNode, createContext, forwardRef, useContext, useMemo } from 'react';

import { useIntl } from 'react-intl';
import { useResizeDetector } from 'react-resize-detector';
import { Outlet } from 'react-router-dom';

import { Box, BoxProps, Slide, useScrollTrigger, useTheme } from '@mui/material';

import { useTheme as useBricksTheme } from '@arrived/bricks';
import { NavigationBarContext } from '@arrived/contexts';
import { FEATURE_FLAGS, useIsFeatureFlagEnabled } from '@arrived/feature-flags';

import { VERIFICATION_STATE, VERIFICATION_STEP } from './models';
import { useEmailVerificationChecker } from './useEmailVerificationChecker';
import { useIsShowingStepper } from './useIsShowingStepper';
import { useVerificationStepperSteps } from './useVerificationStepperSteps';
import { VerificationStepper } from './VerificationStepper';
import { VerificationStepsReturn } from './VerificationSteps';

/**
 * Used to ensure cleanup event removal is run on dismount
 */
const EmailVerificationRefreshOnFocus = () => {
  useEmailVerificationChecker();
  return <></>;
};

interface VerificationStepperContextValues {
  offset: number;
  steps: VerificationStepsReturn;
  isVerified: boolean;
  isLoading?: boolean;
  isShowing: boolean;
}

export const VerificationStepperContext = createContext({} as VerificationStepperContextValues);
export const useVerificationStepperContext = () => useContext(VerificationStepperContext);

/**
 * VerificationStepperWrapper uses 2 VerificationStepper components to render the
 * main desktop version and also the scrolled and mobile minimized version.
 */
export const VerificationStepperWrapper = (_: {
  onUnauthenticatedPress?: () => void;
  isOnBlockedScreen?: boolean;
  children?: ReactNode;
}) => {
  const [isShowing] = useIsShowingStepper();

  const { isVerified, isLoading, steps } = useVerificationStepperSteps();
  const disableEmailVerificationRequirement = useIsFeatureFlagEnabled(
    FEATURE_FLAGS.DISABLE_EMAIL_VERIFICATION_REQUIREMENT,
  );
  const isNewUser = useIsFeatureFlagEnabled(FEATURE_FLAGS.IS_NEW_USER);

  /**
   * Current logic (02/14/2023) is if the user is a new user, then they are required to verify
   * their email before investing. If they are an existing user, they are conditionally required
   * to verify their email based on the disableEmailVerificationRequirement flag.
   */
  const overrideIsVerified = useMemo(
    () =>
      steps.ordered.every((step) => {
        if (disableEmailVerificationRequirement && !isNewUser && step === steps.map[VERIFICATION_STEP.EMAIL])
          return true;
        return step.state === VERIFICATION_STATE.COMPLETE;
      }),
    [steps, disableEmailVerificationRequirement, isNewUser],
  );

  const { ref, height } = useResizeDetector({ handleHeight: true, handleWidth: true });
  const trigger = useScrollTrigger({ disableHysteresis: true, threshold: height ?? 100 });

  return (
    <MinimizedVerificationStepper
      steps={steps}
      isVerified={isVerified || overrideIsVerified}
      isLoading={isLoading}
      isShowing={isShowing}
      minimized={trigger}
    >
      <DesktopVerificationStepper steps={steps} isShowing={isShowing} ref={ref}>
        <Outlet />
      </DesktopVerificationStepper>
    </MinimizedVerificationStepper>
  );
};

interface DesktopVerificationStepperProps extends Omit<BoxProps, 'ref'> {
  isShowing?: boolean;
  steps: VerificationStepsReturn;
}

const DesktopVerificationStepper = forwardRef<HTMLDivElement, DesktopVerificationStepperProps>(
  ({ isShowing, steps, children, ...rest }, ref) => {
    const theme = useTheme();
    const intl = useIntl();
    const bricksTheme = useBricksTheme();

    return (
      <>
        <Box
          zIndex={theme.zIndex.appBar}
          display={{ xs: 'none', lg: 'block' }}
          position="relative"
          ref={ref}
          role="banner"
          aria-label={intl.formatMessage({ id: 'verification-banner.main.label' })}
          {...rest}
        >
          <Slide in={isShowing} unmountOnExit mountOnEnter>
            <Box sx={{ backgroundColor: bricksTheme['surface.neutral.default'].variable }}>
              <EmailVerificationRefreshOnFocus />
              <VerificationStepper
                steps={steps.ordered}
                minimized={false}
                boxProps={{
                  zIndex: 2,
                }}
                context="desktop-verification-stepper"
              />
            </Box>
          </Slide>
        </Box>
        {children}
      </>
    );
  },
);

interface MinimizedVerificationStepperProps extends DesktopVerificationStepperProps {
  isVerified: boolean;
  isLoading?: boolean;
  minimized?: boolean;
}

const MinimizedVerificationStepper = ({
  minimized,
  isVerified,
  isLoading,
  isShowing,
  steps,
  children,
}: MinimizedVerificationStepperProps) => {
  const { ref, height } = useResizeDetector({ handleHeight: true, handleWidth: true });
  const contextValues = useMemo(
    () => ({ offset: height ?? 0, steps, isLoading, isVerified, isShowing: Boolean(isShowing) }),
    [height, steps, isLoading, isVerified, isShowing],
  );
  const { offset, transition } = useContext(NavigationBarContext);
  const theme = useTheme();
  const bricksTheme = useBricksTheme();

  return (
    <VerificationStepperContext.Provider value={contextValues}>
      <Box
        zIndex={theme.zIndex.appBar - 2}
        position="sticky"
        top={offset}
        height={{ lg: 0 }}
        width="100%"
        sx={{
          opacity: {
            xs: 'unset',
            lg: minimized ? 1 : 0,
          },
          backgroundColor: bricksTheme['surface.neutral.default'].variable,
          transition: `top ${transition}, opacity 0.2s ease-in-out`,
        }}
      >
        <Slide in={isShowing} unmountOnExit mountOnEnter>
          <Box
            width="100%"
            borderBottom={({ palette }) => `1px solid ${palette.lightened.iceGray}`}
            sx={{
              backgroundColor: ({ palette }) => palette.neutrals.white,
              pointerEvents: { xs: 'unset', lg: minimized ? 'unset' : 'none' },
            }}
            ref={ref}
          >
            <VerificationStepper
              steps={steps.ordered}
              minimized
              boxProps={{
                sx: {
                  pointerEvents: { xs: 'unset', lg: minimized ? 'unset' : 'none' },
                },
              }}
              context="mimimized-verification-stepper"
            />
          </Box>
        </Slide>
      </Box>
      {children}
    </VerificationStepperContext.Provider>
  );
};
