import { Box, CircularProgress, Stack, Typography } from '@mui/material';

import { useVerificationStepperHeaderState } from './useVerificationStepperSteps';
import { VerificationStepState } from './VerificationSteps';

export interface VerificationStepperHeaderProps {
  steps: VerificationStepState[];
  open?: boolean;
  minimized?: boolean;
  context?: string;
}

const TRANSITION_TIME = '0.5s';

export const VerificationStepperHeader = ({ steps, open, minimized, context }: VerificationStepperHeaderProps) => {
  const [progress, progressStep] = useVerificationStepperHeaderState(steps);
  return (
    <Box display="flex" alignItems="center">
      <Box
        width={{ xs: open ? '4rem' : '2.5rem', lg: minimized ? '3rem' : '6rem' }}
        height={{ xs: open ? '4rem' : '2.5rem', lg: minimized ? '3rem' : '6rem' }}
        position="relative"
        display="flex"
        sx={{
          transition: `all ${TRANSITION_TIME} ease-in-out`,
        }}
      >
        <CircularProgress
          size="inherit"
          sx={{ color: ({ palette }) => palette.lightened.iceBlue, position: 'absolute' }}
          thickness={3}
          value={100}
          variant="determinate"
          aria-labelledby={`verification-stepper.title.${context}`}
        />
        <CircularProgress
          size="inherit"
          color="secondary"
          thickness={3.1}
          value={progress}
          variant="determinate"
          aria-labelledby={`verification-stepper.title.${context}`}
        />
        <Box
          fontSize={{ xs: '1.5rem', lg: minimized ? '1rem' : '2rem' }}
          display="flex"
          justifyContent="center"
          alignItems="center"
          position="absolute"
          width="100%"
          height="100%"
          sx={{
            opacity: { xs: open ? '100%' : '0', lg: minimized ? '0' : '100%' },
            transition: `all ${TRANSITION_TIME} ease-in-out`,
          }}
        >
          <Box component="span" className="emoji" role="img" aria-label={progressStep.emoji.label} aria-hidden="false">
            {progressStep.emoji.symbol}
          </Box>
        </Box>
      </Box>
      <Stack gap={1} ml={2}>
        <Typography variant="h5.semibold" lineHeight="1.5rem" id={`verification-stepper.title.${context}`}>
          {progressStep.title}
        </Typography>
        <Typography variant="body2" display={{ xs: open ? 'block' : 'none', lg: minimized ? 'none' : 'block' }}>
          {progressStep.description}
        </Typography>
      </Stack>
    </Box>
  );
};
