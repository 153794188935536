export const VERIFICATION_STATE = {
  COMPLETE: 'COMPLETE',
  INCOMPLETE: 'INCOMPLETE',
  PENDING: 'PENDING',
  ERROR: 'ERROR',
} as const;
export type VERIFICATION_STATE = (typeof VERIFICATION_STATE)[keyof typeof VERIFICATION_STATE];

export const VERIFICATION_STEP = {
  EMAIL: 'EMAIL',
  ACCOUNT: 'ACCOUNT',
  IDENTITY: 'IDENTITY',
  BANK: 'BANK',
} as const;
export type VERIFICATION_STEP = (typeof VERIFICATION_STEP)[keyof typeof VERIFICATION_STEP];
